import React, {useEffect, useState} from 'react';
import './App.css';
import TelegramWebApp from '@twa-dev/sdk';

function App() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Проверяем, что SDK загружен и Web App готов к использованию
    if (TelegramWebApp) {
      TelegramWebApp.ready();
    }
  }, []);

  const handleClick = () => {
    setCount(count + 1);
  };

  return (
    <div style={styles.container}>
      <button style={styles.button} onClick={handleClick}>
        Нажатий: {count}
      </button>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  },
  button: {
    padding: '15px 30px',
    fontSize: '18px',
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    width: '20rem',
    height: '20rem',
    borderRadius: '50%',
    cursor: 'pointer',
  },
};

export default App;
